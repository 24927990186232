import { cn } from '@/utils/cn'
import { Slot } from '@radix-ui/react-slot'
import { type VariantProps, cva } from 'class-variance-authority'
import { type ButtonHTMLAttributes, type HTMLAttributes, forwardRef } from 'react'
import { ArrowIcon } from '../icons/arrow'

const primaryStyles = cn(
  'rounded-full border border-turquoise bg-turquoise text-center font-bold text-white',
  'hover:border-turquoise-dark hover:bg-turquoise-dark focus-visible:border-turquoise-dark focus-visible:bg-turquoise-dark active:border-turquoise-dark active:bg-turquoise-dark',
  '[&_.button-icon]:hover:before:scale-125',
  'disabled:border-light disabled:bg-light',
  '[&:has(.button-icon)>span:first-child]:mx-2 [&:has(.button-icon)>span:first-child]:w-full'
)

const buttonVariants = cva(
  'inline-flex items-center justify-center whitespace-nowrap outline-none transition-all disabled:cursor-not-allowed',
  {
    variants: {
      variant: {
        primary: cn(primaryStyles, '[&_.button-icon]:bg-white [&_.button-icon]:text-black'),
        secondary: cn(
          primaryStyles,
          'bg-white text-black',
          '[&_.button-icon]:bg-turquoise [&_.button-icon]:text-white',
          'hover:text-white focus-visible:text-white active:text-white [&_.button-icon]:hover:bg-white [&_.button-icon]:hover:text-black'
        ),
        square: cn(
          'rounded-lg bg-turquoise text-white',
          'hover:bg-turquoise-dark focus-visible:bg-turquoise-dark active:bg-turquoise-dark'
        ),
        default: 'hover:text-turquoise-dark focus-visible:text-turquoise-dark active:text-turquoise-dark',
      },
      size: {
        icon: 'h-8 w-8',
        default: '',
        small: 'h-12 px-3',
        medium: 'h-14 px-3',
      },
    },
    defaultVariants: {
      variant: 'primary',
      size: 'medium',
    },
  }
)

type ButtonVariants = VariantProps<typeof buttonVariants>

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement>, ButtonVariants {
  asChild?: boolean
}

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant, size, asChild = false, ...props }, ref) => {
    const Comp = asChild ? Slot : 'button'
    return <Comp className={cn(buttonVariants({ variant, size, className }))} ref={ref} type="button" {...props} />
  }
)
Button.displayName = 'Button'

export const ButtonArrow = ({ className, ...props }: HTMLAttributes<HTMLSpanElement>) => {
  return (
    <span
      className={cn(
        'button-icon relative flex h-[1.875rem] w-[1.875rem] min-w-[1.875rem] items-center justify-center rounded-full transition-all',
        'before:absolute before:size-full before:rounded-full before:bg-inherit before:transition-transform',
        className
      )}
      {...props}
    >
      <ArrowIcon size={16} className="relative" />
    </span>
  )
}
