import { navigation, socials } from '@/navigation'
import { Link } from '@remix-run/react'
import { Button } from './common/button'
import { Container } from './common/container'
import { GithubIcon } from './icons/github'
import { MediumIcon } from './icons/medium'
import { XIcon } from './icons/x'

export const Header = () => {
  return (
    <header className="w-full">
      <Container>
        <div className="relative grid w-full gap-6 xl:grid-cols-4">
          <div className="fixed top-0 left-0 z-50 h-14 w-full backdrop-blur xl:w-1/2 xl:rounded-br-3xl" />
          <nav className="-translate-x-1/2 md:-translate-x-0 fixed top-0 left-1/2 z-50 flex items-center gap-6 py-4 mix-blend-difference md:left-[unset]">
            <Button variant="default" size="default" className="font-bold text-white" asChild>
              <Link to={`#${navigation.learnMore}`}>About us</Link>
            </Button>
            <Button variant="default" size="default" className="font-bold text-white" asChild>
              <Link to={`#${navigation.stake}`}>Stake</Link>
            </Button>
            <Button variant="default" size="default" className="font-bold text-white" asChild>
              <Link to={`#${navigation.contact}`}>Contact</Link>
            </Button>
          </nav>
          <div className="fixed top-0 z-50 mr-20 hidden items-center gap-5 py-3 md:right-0 md:flex xl:right-1/2">
             <Button variant="square" size="icon" asChild>
              <a href={socials.x} target="_blank" rel="noreferrer">
                <XIcon />
              </a>
            </Button>
            <Button variant="square" size="icon" asChild>
              <a href={socials.github} target="_blank" rel="noreferrer">
                <GithubIcon />
              </a>
            </Button>
            <Button variant="square" size="icon" asChild>
              <a href={socials.medium} target="_blank" rel="noreferrer">
                <MediumIcon />
              </a>
            </Button>
          </div>
        </div>
      </Container>
    </header>
  )
}
