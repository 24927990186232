import { cn } from '@/utils/cn'
import { Corner, Root, ScrollAreaScrollbar, ScrollAreaThumb, Viewport } from '@radix-ui/react-scroll-area'
import { type ComponentPropsWithoutRef, type ElementRef, forwardRef } from 'react'

export const ScrollArea = forwardRef<ElementRef<typeof Root>, ComponentPropsWithoutRef<typeof Root>>(
  ({ className, children, ...props }, ref) => (
    <Root ref={ref} className={cn('relative overflow-hidden', className)} {...props}>
      <Viewport className="[&>div]:!block h-full w-full overscroll-none" data-role="scroll-area-viewport">
        {children}
      </Viewport>
      <ScrollBar />
      <Corner />
    </Root>
  )
)
ScrollArea.displayName = Root.displayName

export const ScrollBar = forwardRef<
  ElementRef<typeof ScrollAreaScrollbar>,
  ComponentPropsWithoutRef<typeof ScrollAreaScrollbar>
>(({ className, orientation = 'vertical', ...props }, ref) => (
  <ScrollAreaScrollbar
    ref={ref}
    orientation={orientation}
    className={cn(
      'flex touch-none select-none transition-colors',
      orientation === 'vertical' && 'h-full w-3 border-l border-l-transparent p-1',
      orientation === 'horizontal' && 'h-3 flex-col border-t border-t-transparent p-1',
      className
    )}
    {...props}
  >
    <ScrollAreaThumb className="relative flex-1 rounded-full bg-white/20" />
  </ScrollAreaScrollbar>
))
ScrollBar.displayName = ScrollAreaScrollbar.displayName
