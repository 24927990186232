import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
  CarouselViewport,
} from '@/components/common/carousel'
import { Container } from '@/components/common/container'
import { Card, type CardProps } from '@/components/shared/card'
import { navigation } from '@/navigation'
import { cn } from '@/utils/cn'
import { useIntersectionObserver } from '@/utils/useIntersectionObserver'
import Autoplay from 'embla-carousel-autoplay'
import { useRef } from 'react'
import dashboardImage from '/images/learn-dashboard.jpg'
import eventsImage from '/images/learn-events.jpg'
import toolsImage from '/images/learn-tools.png'
import tools2Image from '/images/learn-tools2.png'
import guidesImage from '/images/testnet-guide.png'

const CARDS: CardProps[] = [
  {
    badge: 'Dashboard',
    title: 'Blobs and Gas Tracker',
    description: (
  <>
    Track{' '}
    <a
      href="https://pfb-tracker.qubelabs.io/"
      target="_blank"
      rel="noreferrer"
      style={{ textDecoration: 'underline' }}
    >
      PayForBlob
    </a>{' '}
    data posted to Celestia’s DA layer and monitor gas prices in real-time. This dynamic tracker updates with every new block, offering seamless insights into rollup activity and network performance.
  </>
),
image: dashboardImage,
  },
  {
    badge: 'Events',
    title: 'Modular Meetup',
   description: (
  <>
    In collaboration with Celestia, we organized a{' '}
    <a
      href="https://x.com/qubelabs/status/1752358372706271318"
      target="_blank"
      rel="noreferrer"
      style={{ textDecoration: 'underline' }}
    >
      Modular Meetup
    </a>{' '}
    that brought together developers, contributors, and blockchain enthusiasts for discussions on the modular ecosystem, live demos, and interactive sessions.
  </>
),
image: eventsImage,
  },
  {
    badge: 'Tools',
    title: 'Building for Communities',
    description:
      'We have developed a wide range of tools to support communities, including custom Discord and Telegram bots, token faucets for testnets, and network utilities such as validator alerting systems and health check tools.',
    image: tools2Image,
  },
  {
    badge: 'Education',
    title: 'Educational Content',
description: (
  <>
    We created detailed guides for{' '}
    <a
      href="https://docs.celestia.org/learn/how-to-stake-tia"
      target="_blank"
      rel="noreferrer"
      style={{ textDecoration: 'underline' }}
    >
      staking
    </a>
    ,{' '}
    <a
      href="https://docs.axelar.dev/validator/external-chains/overview/"
      target="_blank"
      rel="noreferrer"
      style={{ textDecoration: 'underline' }}
    >
      validator services
    </a>
    ,{' '}
    <a
      href="https://docs.celestia.org/how-to-guides/ibc-relayer"
      target="_blank"
      rel="noreferrer"
      style={{ textDecoration: 'underline' }}
    >
      IBC relayers
    </a>
    , along with{' '}
    <a
      href="https://www.youtube.com/watch?v=ClvgALDFbxU"
      target="_blank"
      rel="noreferrer"
      style={{ textDecoration: 'underline' }}
    >
      educational videos
    </a>{' '}
    for{' '}
    <a
      href="https://www.youtube.com/watch?v=_RlaKSIkF40"
      target="_blank"
      rel="noreferrer"
      style={{ textDecoration: 'underline' }}
    >
      community initiatives
    </a>{' '}
    and{' '}
    <a
      href="https://docs.google.com/document/d/1xWsgT2i9x2Birtt7Rm2mQNu2kCLkfUrue9LljwoMYU8/edit?tab=t.0"
      target="_blank"
      rel="noreferrer"
      style={{ textDecoration: 'underline' }}
    >
      step-by-step testnet guides
    </a>
    .
  </>
),
image: guidesImage,


  },
  
]

export const LearnMore = () => {
  const ref = useRef<HTMLDivElement>(null)
  const isVisible = useIntersectionObserver(ref, { threshold: 0.1 })
  const carouselPlugins = [Autoplay({ delay: 10_000, stopOnMouseEnter: true, playOnInit: isVisible })]

  return (
    <section ref={ref} id={navigation.learnMore} className="py-20 md:py-32">
      <Container className="px-0 md:max-w-full md:px-0">
        <Carousel className="flex-col" plugins={carouselPlugins}>
          <CarouselViewport className="max-w-full px-4 [mask-size:1568px_auto] md:px-0 xl:max-w-[min(100%,1568px)] xl:[mask-image:linear-gradient(90deg,transparent,black_64px,black_calc(100%-64px),transparent)] xl:[mask-position:50%_50%]">
            <CarouselContent className="-ml-10 md:-ml-0 md:mx-12 md:px-0 xl:mx-32 2xl:mx-40">
              {CARDS.map((card, index) => (
                <CarouselItem
                  key={index + card.title}
                  className={cn('basis-full pl-10 last:pr-0 md:basis-[564px] md:last:pr-10')}
                >
                  <Card className="h-full" {...card} />
                </CarouselItem>
              ))}
            </CarouselContent>
          </CarouselViewport>
          <Container className="static mt-8 flex w-full max-w-full items-center justify-center gap-6 px-4 md:absolute md:mt-0 md:h-0 md:justify-between md:px-4 xl:max-w-[1320px] xl:px-0">
            <CarouselPrevious className="" />
            <CarouselNext className="" />
          </Container>
        </Carousel>
      </Container>
    </section>
  )
}
