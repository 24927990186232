import { cn } from '@/utils/cn'
import * as DialogPrimitive from '@radix-ui/react-dialog'
import React from 'react'
import { CloseIcon } from '../icons/close'

export const Dialog = DialogPrimitive.Root
Dialog.displayName = DialogPrimitive.Dialog.displayName

export const DialogTrigger = DialogPrimitive.Trigger
DialogTrigger.displayName = DialogPrimitive.Trigger.displayName

const DialogPortal = DialogPrimitive.Portal
DialogPortal.displayName = DialogPrimitive.DialogPortal.displayName

export const DialogClose = DialogPrimitive.Close
DialogClose.displayName = DialogPrimitive.Close.displayName

const DialogOverlay = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Overlay>,
  React.ComponentPropsWithoutRef<typeof DialogPrimitive.Overlay>
>(({ className, ...props }, ref) => (
  <DialogPrimitive.Overlay
    ref={ref}
    className={cn(
      'fixed inset-0 z-50',
      'data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:animate-out data-[state=open]:animate-in',
      className
    )}
    {...props}
  />
))
DialogOverlay.displayName = DialogPrimitive.Overlay.displayName

export const DialogContent = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof DialogPrimitive.Content>
>(({ className, children, ...props }, ref) => (
  <DialogPortal>
    <DialogOverlay />
    <DialogPrimitive.Content
      ref={ref}
      className={cn(
        'fixed top-0 right-0 z-50 h-full w-full max-w-sm border-[#272727] border-l bg-black/70 backdrop-blur-sm transition-all',
        'data-[state=closed]:animate-out data-[state=open]:animate-in',
        'data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0',
        'data-[state=closed]:slide-out-to-right data-[state=open]:slide-in-from-right',
        className
      )}
      {...props}
    >
      {children}
      <DialogPrimitive.Close
        className={cn(
          'absolute top-7 right-7 flex h-10 w-10 min-w-10 items-center justify-center rounded-full border border-white p-2 text-white transition-all',
          'hover:border-turquoise hover:text-turquoise focus-visible:border-turquoise focus-visible:text-turquoise',
          'disabled:pointer-events-none data-[state=open]:bg-accent data-[state=open]:text-muted-foreground'
        )}
      >
        <CloseIcon size={16} />
        <span className="sr-only">Close</span>
      </DialogPrimitive.Close>
    </DialogPrimitive.Content>
  </DialogPortal>
))
DialogContent.displayName = DialogPrimitive.Content.displayName

export const DialogTitle = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Title>,
  React.ComponentPropsWithoutRef<typeof DialogPrimitive.Title>
>(({ className, ...props }, ref) => <DialogPrimitive.Title ref={ref} className={cn(className)} {...props} />)
DialogTitle.displayName = DialogPrimitive.Title.displayName

export const DialogDescription = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Description>,
  React.ComponentPropsWithoutRef<typeof DialogPrimitive.Description>
>(({ className, ...props }, ref) => <DialogPrimitive.Description ref={ref} className={cn(className)} {...props} />)
DialogDescription.displayName = DialogPrimitive.Description.displayName
