import { SquareAnimation } from '@/components/animations/square'
import { Button, ButtonArrow } from '@/components/common/button'
import { Container } from '@/components/common/container'
import { navigation } from '@/navigation'
import { cn } from '@/utils/cn'
import { Link } from '@remix-run/react'
import logo from '/images/logo.png'

export const Hero = () => {
  return (
    <section className="relative bg-white">
      <Container>
        <div className="grid w-full gap-6 xl:grid-cols-2">
          <div
            className={cn(
              'flex flex-col items-center justify-center gap-8',
              'pt-24 pb-10 text-center',
              'md:items-start md:gap-10 md:pt-32 md:pb-20 md:text-left',
              'xl:max-w-[30rem] xl:py-48 xl:pb-48'
            )}
          >
            <img src={logo} alt="Qubelabs" className="h-14 w-auto object-contain" />
            <h1 className="font-medium text-4xl leading-tight md:text-5xl">
              Empowering networks to build a decentralized future
            </h1>
            <nav className="flex flex-wrap gap-4">
              <Button className="min-w-44 flex-grow md:min-w-52 md:flex-grow-0" asChild>
                <Link to={`#${navigation.workWithUs}`}>
                  <span>Work With Us</span>
                  <ButtonArrow />
                </Link>
              </Button>
              <Button className="min-w-32 flex-grow md:min-w-32 md:flex-grow-0" variant="secondary" asChild>
                <Link to={`#${navigation.stake}`}>
                  <span>Stake</span>
                  <ButtonArrow />
                </Link>
              </Button>
            </nav>
          </div>
        </div>
      </Container>
      <SquareAnimation />
    </section>
  )
}
