import { cn } from '@/utils/cn'
import { Badge } from '../common/badge'

export interface CardProps {
  badge: string
  title: string
  description: string
  image: string
  imageDescription?: string
  className?: string
}

export const Card = ({ badge, title, description, image, imageDescription, className }: CardProps) => {
  return (
    <div
      className={cn(
        'group flex flex-col gap-4 rounded-lg border border-light bg-light/20 p-6 md:gap-6 md:p-10',
        className
      )}
    >
      <Badge className="min-h-8 md:min-h-9">{badge}</Badge>
      <h4 className="font-bold text-base leading-tight md:text-2xl">{title}</h4>
      <p className="text-black/70 text-xs md:text-sm">{description}</p>
      <div className="relative mt-auto h-40 overflow-hidden rounded-lg grayscale transition-all group-hover:grayscale-0 md:h-72">
        <img
          src={image}
          alt={imageDescription}
          className="w-full object-cover transition-transform group-hover:scale-125"
        />
      </div>
    </div>
  )
}
