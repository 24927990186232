import { cn } from '@/utils/cn'
import { type VariantProps, cva } from 'class-variance-authority'
import { type InputHTMLAttributes, type TextareaHTMLAttributes, forwardRef } from 'react'

const inputVariants = cva(
  cn(
    'flex w-full rounded-lg border border-black/20 bg-white/20 px-4 font-normal outline-none transition-all',
    'hover:border-turquoise focus-visible:border-turquoise',
    'disabled:cursor-not-allowed disabled:opacity-50'
  ),
  {
    variants: {
      variant: {
        white: 'text-white/70 placeholder:text-white/50',
        black: 'text-black placeholder:text-black/70',
      },
    },
    defaultVariants: {
      variant: 'white',
    },
  }
)

export const Input = forwardRef<
  HTMLInputElement,
  InputHTMLAttributes<HTMLInputElement> & VariantProps<typeof inputVariants>
>(({ type, variant, className, ...props }, ref) => {
  return <input type={type} className={cn(inputVariants({ variant }), 'h-14', className)} ref={ref} {...props} />
})
Input.displayName = 'Input'

export const Textarea = forwardRef<
  HTMLTextAreaElement,
  TextareaHTMLAttributes<HTMLTextAreaElement> & VariantProps<typeof inputVariants>
>(({ variant, className, ...props }, ref) => {
  return <textarea className={cn(inputVariants({ variant }), 'py-4', className)} ref={ref} {...props} />
})
