import { type IconProps, iconVariants } from '@/components/icons/common'
import { cn } from '@/utils/cn'

export function CloseIcon({ className, size = 24, ...props }: IconProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 -960 960 960"
      color="currentColor"
      className={cn(iconVariants({ size, className }))}
      {...props}
    >
      <path
        d="m256-200-56-56 224-224-224-224 56-56 224 224 224-224 56 56-224 224 224 224-56 56-224-224-224 224Z"
        fill="currentColor"
      />
    </svg>
  )
}
