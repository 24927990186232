import { CurveAnimation, CurveAnimationContainer, getAnimationTargetParams } from '@/components/animations/curve'
import { Badge } from '@/components/common/badge'
import { Button, ButtonArrow } from '@/components/common/button'
import { Container } from '@/components/common/container'
import { Input, Textarea } from '@/components/common/input'
import { Label } from '@/components/common/label'
import { navigation } from '@/navigation'
import { cn } from '@/utils/cn'
import { zodResolver } from '@hookform/resolvers/zod'
import axios from 'axios'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { z } from 'zod'

export const Contact = () => {
  const turquoiseCurveParams = getAnimationTargetParams()
  const orangeCurveParams = getAnimationTargetParams()

  return (
    <section id={navigation.contact} className="flex bg-black py-20">
      <CurveAnimationContainer className="overflow-visible">
        <CurveAnimation
          params={turquoiseCurveParams}
          variant="turquoise"
          className={cn('rotate-[135deg]', 'bottom-0 left-0')}
        />
        <CurveAnimation params={orangeCurveParams} variant="orange" className={cn('right-0 bottom-0')} />
      </CurveAnimationContainer>
      <Container className="relative">
        <div className="grid gap-x-20 gap-y-6 xl:grid-cols-8">
          <div className="flex flex-col gap-6 xl:col-span-3">
            <h4 className="text-center font-medium text-3xl text-white leading-tight md:text-left md:text-5xl">
              We’d love to hear from <span className="text-turquoise">you</span>
            </h4>
            <p className="text-base text-white/70 md:text-xl">
              Either fill out the form with your inquiry or contact us directly via{' '}
              <Button variant="default" size="default" className="font-bold text-turquoise" asChild>
                <a href="mailto:hello@qubelabs.io" target="_blank" rel="noreferrer">
                  hello@qubelabs.io
                </a>
              </Button>{' '}
              and we will respond as early as possible.
            </p>
            <p className="font-bold text-base text-turquoise md:text-xl">We look forward to hearing from you!</p>
          </div>
          <div className="xl:col-span-5 xl:ml-4">
            <ContactForm />
          </div>
        </div>
      </Container>
    </section>
  )
}

const formSchema = z.object({
  name: z.string().min(1, 'Name is required'),
  email: z.string().email('Invalid email address'),
  message: z.string().min(1, 'Message is required'),
})

type FormData = z.infer<typeof formSchema>

const ContactForm = () => {
  const [responseMessage, setResponseMessage] = useState<string | null>(null)
  const [isError, setIsError] = useState(false)

  const form = useForm<FormData>({
    resolver: zodResolver(formSchema),
  })

  const { register, handleSubmit, reset, formState } = form
  const { errors } = formState

  const onSubmit = handleSubmit(async (data: FormData) => {
    try {
      await axios.post('https://qubelabs.io/sendemail.php', data)
      setResponseMessage(
        'Thank you for getting in touch with us! Your message has been successfully received, and we will respond as soon as possible.'
      )
      setIsError(false)
      reset()
    } catch (error) {
      setResponseMessage('There was an error submitting the form.')
      console.error('Error submitting form:', error)
      setIsError(true)
    }
  })

  return (
    <form className="flex flex-col gap-6" onSubmit={onSubmit} noValidate>
      <div className="grid items-start gap-6 md:grid-cols-2">
        <Label className="grid gap-2">
          <span>Name</span>
          <Input id="name" type="text" placeholder="Name..." className="peer" {...register('name')} />
          {errors.name && (
            <Badge color="warning" className="w-full justify-start text-left">
              {errors.name.message}
            </Badge>
          )}
        </Label>
        <Label className="grid gap-2">
          <span>Email</span>
          <Input id="email" type="email" placeholder="your@email.com" className="peer" {...register('email')} />
          {errors.email && (
            <Badge color="warning" className="w-full justify-start text-left">
              {errors.email.message}
            </Badge>
          )}
        </Label>
      </div>
      <Label className="grid gap-2">
        <span>Message</span>
        <Textarea
          id="message"
          placeholder="Write your message...."
          rows={6}
          className="peer"
          {...register('message')}
        />
        {errors.message && (
          <Badge color="warning" className="w-full justify-start text-left">
            {errors.message.message}
          </Badge>
        )}
      </Label>
      {responseMessage && (
        <Badge color={isError ? 'warning' : 'turquoise'} className="w-full justify-start text-left">
          {responseMessage}
        </Badge>
      )}
      <Button type="submit" className="mx-auto w-fit min-w-52">
        <span>Send</span>
        <ButtonArrow />
      </Button>
    </form>
  )
}
