export const navigation = {
  workWithUs: 'work-with-us',
  learnMore: 'learn-more',
  testimonials: 'testimonials',
  contact: 'contact',
  stake: 'stake',
}

export const socials = {
  medium: 'https://medium.com/qubelabs',
  x: 'https://x.com/qubelabs',
  github: 'https://github.com/qubelabsio',
}
