import { TypingAnimation } from '@/components/animations/typing'

import { Button } from '@/components/common/button'
import { Container } from '@/components/common/container'
import { ArrowIcon } from '@/components/icons/arrow'
import { ErrorIcon } from '@/components/icons/error'
import { LoadingIcon } from '@/components/icons/loading'
import { StakeProject } from '@/components/shared/stakeProject'
import { STAKE_PROJECTS } from '@/consts/stakeProjects'
import { navigation } from '@/navigation'
import { useIntersectionObserver } from '@/utils/useIntersectionObserver'
import { useQuery } from '@tanstack/react-query'
import { useRef } from 'react'

async function fetchStakeProjectData() {
  const response = await fetch('https://chains.cosmos.directory')
  const stakingData = await response.json()
  const chainsData = stakingData['chains']

  return STAKE_PROJECTS.map((item) => {
    const match = chainsData.find((project: any) => project.name === item.fetchId)

    return {
      ...item,
      apr: match && match.params.estimated_apr,
    }
  })
}

export const Stake = () => {
  const { data: projects, isLoading, error } = useQuery({ queryKey: ['stakeData'], queryFn: fetchStakeProjectData })

  const titleRef = useRef<HTMLDivElement>(null)
  const isVisible = useIntersectionObserver(titleRef, { threshold: 0.1 })

  return (
    <section id={navigation.stake} className="flex bg-turquoise-black py-20 text-white md:py-32">
      <Container className="relative z-[1]">
        <div
          ref={titleRef}
          className="mx-auto flex max-w-xl flex-col items-center gap-6 text-center text-white xl:max-w-4xl"
        >
          <h3 className="sr-only">Stake with us</h3>
          {/* biome-ignore lint/a11y/useHeadingContent: <explanation> */}
          <h3
            className="min-h-[calc(1.875rem*1.2)] font-medium text-3xl text-white leading-tight md:min-h-[calc(3.75rem*1.2)] md:text-6xl"
            aria-hidden
          >
            <TypingAnimation start={isVisible}>Stake with us</TypingAnimation>
          </h3>
          <p className="text-base md:text-2xl">
            <span className="font-bold">Earn rewards for staking your tokens.</span>
            <br />
            <span className="text-white/70">
              Here are the networks we validate, support and help grow their communities.
            </span>
          </p>
          <Button
            variant="default"
            size="default"
            className="flex items-center gap-2 font-medium text-xt hover:text-white hover:underline focus-visible:text-white focus-visible:underline"
            asChild
          >
            <a href="https://medium.com/@qubelabs" target="_blank" rel="noreferrer">
              <span>Learn more about staking</span>
              <ArrowIcon />
            </a>
          </Button>
        </div>

        {isLoading && (
          <div className="mt-10 flex min-h-24 items-center justify-center gap-4 rounded-lg border border-white/20 p-4 text-white md:mt-20 xl:mx-10 xl:gap-6 xl:p-6">
            <LoadingIcon className="animate-spin" />
            <p className="font-bold text-base xl:text-2xl">Loading staking offers...</p>
          </div>
        )}

        {error && (
          <div className="mt-10 flex min-h-24 items-center justify-center gap-4 rounded-lg border border-white/20 p-4 text-white md:mt-20 xl:mx-10 xl:gap-6 xl:p-6">
            <ErrorIcon />
            <p className="font-bold text-base xl:text-2xl">Loading staking offers failed!</p>
          </div>
        )}

        <ul className="mt-10 grid gap-4 md:mt-20 md:gap-6 lg:grid-cols-2 xl:gap-x-10 xl:gap-y-6 xl:px-10">
          {projects?.map((project, index) => (
            <li key={`stake-project-${project.name}-${index}`} className="w-full">
              <StakeProject {...project} />
            </li>
          ))}
        </ul>
      </Container>
    </section>
  )
}
