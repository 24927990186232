import { Children, type ReactNode, cloneElement, isValidElement, useEffect, useState } from 'react'

interface TypingAnimationProps {
  children: ReactNode
  speed?: number
  remainCursor?: boolean
  start?: boolean
  onComplete?: () => void
}

export const TypingAnimation = ({
  children,
  speed = 50,
  remainCursor = false,
  start = true,
  onComplete,
}: TypingAnimationProps) => {
  const [displayedText, setDisplayedText] = useState('')
  const [index, setIndex] = useState(0)
  const [isCursorVisible, setCursorVisible] = useState(false)

  const extractText = (content: ReactNode): string => {
    return Children.toArray(content)
      .map((child) => {
        if (typeof child === 'string') return child
        if (isValidElement(child)) return extractText(child.props.children)
        return ''
      })
      .join('')
  }

  const text = extractText(children)

  useEffect(() => {
    if (!start) {
      setDisplayedText('')
      setIndex(0)
      setCursorVisible(false)
      return
    }

    if (index < text.length) {
      setCursorVisible(true)
      const timeout = setTimeout(() => {
        setDisplayedText(text.substring(0, index + 1))
        setIndex(index + 1)
      }, speed)
      return () => clearTimeout(timeout)
    } else {
      if (onComplete) {
        onComplete()
      }
      if (!remainCursor) {
        setCursorVisible(false)
      }
    }
  }, [index, text, speed, start, onComplete, remainCursor])

  const renderTypedContent = (content: ReactNode, visibleText: string): ReactNode => {
    let currentIndex = 0

    return Children.map(content, (child) => {
      if (typeof child === 'string') {
        const visiblePart = visibleText.substring(currentIndex, currentIndex + child.length)
        currentIndex += child.length
        return visiblePart
      }

      if (isValidElement(child)) {
        const nestedText = extractText(child.props.children)
        const visibleNestedText = visibleText.substring(currentIndex, currentIndex + nestedText.length)
        currentIndex += nestedText.length

        return cloneElement(child, {}, renderTypedContent(child.props.children, visibleNestedText))
      }

      return null
    })
  }

  return (
    <span className="relative">
      {renderTypedContent(children, displayedText)}
      {isCursorVisible && <span className="absolute inline-block animate-blink">|</span>}
    </span>
  )
}
