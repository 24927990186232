import { type VariantProps, cva } from 'class-variance-authority'
import type { ComponentPropsWithoutRef } from 'react'

export const iconVariants = cva('block', {
  variants: {
    size: {
      16: 'min-h-4 min-w-4',
      24: 'min-h-6 min-w-6',
      48: 'min-h-12 min-w-12',
    },
    direction: {
      left: '[&_[data-direction]]:rotate-180',
      down: '[&_[data-direction]]:rotate-90',
      up: '[&_[data-direction]]:-rotate-90',
      right: '[&_[data-direction]]:rotate-0',
    },
  },
})

type IconSize = NonNullable<VariantProps<typeof iconVariants>['size']>

export interface IconProps extends ComponentPropsWithoutRef<'svg'>, VariantProps<typeof iconVariants> {
  size?: IconSize
  direction?: 'left' | 'down' | 'up' | 'right'
}
