import { cn } from '@/utils/cn'
import { type IconProps, iconVariants } from './common'

export function XIcon({ className, size = 24, ...props }: IconProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 32 32"
      color="currentColor"
      className={cn(iconVariants({ size, className }))}
      {...props}
    >
      <path
        d="M17.9784 14.4074L25.7217 5.59998H23.8868L17.1633 13.2473L11.7933 5.59998H5.59961L13.7201 17.1641L5.59961 26.4H7.43462L14.5348 18.3241L20.2059 26.4H26.3996L17.978 14.4074H17.9784ZM15.4651 17.266L14.6424 16.1144L8.0958 6.95164H10.9143L16.1974 14.3463L17.0202 15.4978L23.8877 25.1098H21.0692L15.4651 17.2664V17.266Z"
        fill="currentColor"
      />
    </svg>
  )
}
