import { Contact } from '@/sections/contact'
import { Hero } from '@/sections/hero'
import { LearnMore } from '@/sections/learnMore'
import { Stake } from '@/sections/stake'
import { Subscribe } from '@/sections/subscribe'
import { Testimonials } from '@/sections/testimonials'
import { WorkWithUs } from '@/sections/workWithUs'
import type { MetaFunction } from '@remix-run/node'

export const meta: MetaFunction = () => {
  return [
    { title: 'Qubelabs | Empowering the Decentralized Future' },
    { description: 'Your trusted partner for blockchain consultancy and validating services of Web 3.0 Ecosystem.' },
    { keywords: 'qubelabs, validator, staking, crypto, cryptocurrency, defi, axelar, evmos, agoric, umee' },
    { author: 'Qubelabs' },
    { 'og:title': 'Qubelabs | Empowering the Decentralized Future' },
    {
      'og:description': 'Your trusted partner for blockchain consultancy and validating services of Web 3.0 Ecosystem.',
    },
    { 'og:type': 'website' },
    { 'og:url': 'https://qubelabs.io/' },
    { 'og:image': 'https://qubelabs.io/og_image.jpg' },
    { 'twitter:card': 'summary_large_image' },
    { 'twitter:title': 'Qubelabs | Empowering the Decentralized Future' },
    {
      'twitter:description':
        'Your trusted partner for blockchain consultancy and validating services of Web 3.0 Ecosystem.',
    },
    { 'twitter:image': 'https://qubelabs.io/twitter_image.jpg' },
  ]
}

export default function Index() {
  return (
    <>
      <Hero />
      <WorkWithUs />
      <LearnMore />
      <Testimonials />
      <div className="relative">
        <Stake />
        <Contact />
      </div>
      <Subscribe />
    </>
  )
}
