import { CurveAnimation, CurveAnimationContainer, getAnimationTargetParams } from '@/components/animations/curve'
import { TypingAnimation } from '@/components/animations/typing'
import { Badge } from '@/components/common/badge'
import { Button, ButtonArrow } from '@/components/common/button'
import { Container } from '@/components/common/container'
import { navigation } from '@/navigation'
import { cn } from '@/utils/cn'
import { useIntersectionObserver } from '@/utils/useIntersectionObserver'
import { Link } from '@remix-run/react'
import { type HTMLAttributes, useRef } from 'react'

export const WorkWithUs = () => {
  const turquoiseCurveParams = getAnimationTargetParams()
  const titleRef = useRef<HTMLDivElement>(null)
  const isVisible = useIntersectionObserver(titleRef, { threshold: 0.1 })

  return (
    <section id={navigation.workWithUs} className="relative flex bg-black py-20 xl:py-44">
      <CurveAnimationContainer>
        <CurveAnimation
          params={turquoiseCurveParams}
          variant="turquoise"
          className={cn('rotate-90', '-right-1/2 top-1/4', 'md:right-0 md:bottom-0', 'xl:top-1/2 xl:right-0')}
        />
      </CurveAnimationContainer>
      <Container className="relative">
        <div className="grid gap-x-20 gap-y-6 xl:grid-cols-8">
          <div ref={titleRef} className="flex flex-col gap-6 xl:col-span-3">
            <Badge>About us</Badge>
            <h2 className="sr-only">Work with us!</h2>
            {/* biome-ignore lint/a11y/useHeadingContent: <explanation> */}
            <h2
              className="min-h-[calc(1.875rem*1.2)] font-medium text-3xl text-white leading-tight md:min-h-[calc(3.75rem*1.2)] md:text-6xl"
              aria-hidden
            >
              <TypingAnimation start={isVisible}>Work with us!</TypingAnimation>
            </h2>
            <Copy className="block md:my-4 xl:hidden" />
            <Button className="w-fit min-w-52" asChild>
              <Link to={`#${navigation.learnMore}`}>
                <span>Learn more</span>
                <ButtonArrow />
              </Link>
            </Button>
          </div>
          <Copy className="ml-4 hidden xl:col-span-5 xl:block" />
        </div>
      </Container>
    </section>
  )
}

const Copy = ({ className, ...props }: HTMLAttributes<HTMLDivElement>) => {
  return (
    <div className={cn('text-base md:text-xl', className)} {...props}>
      <p className="text-white/70">
        <span className="font-bold">Qubelabs</span> bridges innovation and expertise to support the Web 3.0 ecosystem. From blockchain consultancy to validation services, we empower networks to thrive by building strong communities and providing hands-on support tailored to their needs.
      </p>
      <p className="mt-6 font-bold text-white">
        By working with us, you get a committed partner that contributes to the growth and development of a decentralized future.
      </p>
    </div>
  )
}
