import { CurveAnimation, CurveAnimationContainer, getAnimationTargetParams } from '@/components/animations/curve'
import { Badge } from '@/components/common/badge'
import { Button, ButtonArrow } from '@/components/common/button'
import { Container } from '@/components/common/container'
import { Input } from '@/components/common/input'
import { Label } from '@/components/common/label'
import { navigation } from '@/navigation'
import { cn } from '@/utils/cn'
import { zodResolver } from '@hookform/resolvers/zod'
import axios from 'axios'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { z } from 'zod'

export const Subscribe = () => {
  const turquoiseCurveParams = getAnimationTargetParams()
  const orangeCurveParams = getAnimationTargetParams()

  return (
    <section id={navigation.contact} className="relative flex bg-white py-20 md:py-24">
      <CurveAnimationContainer>
        <div className="-translate-y-1/2 pointer-events-none absolute top-1/2 left-0 size-96 select-none bg-mesh opacity-15 [background-size:44px_44px] [mask-image:radial-gradient(black,transparent_50%)]" />
        <CurveAnimation
          params={turquoiseCurveParams}
          variant="turquoise"
          className={cn('-rotate-[45deg]', 'top-0 left-0')}
        />
        <CurveAnimation params={orangeCurveParams} variant="orange" className={cn('right-0 bottom-0 rotate-180')} />
      </CurveAnimationContainer>
      <Container className="relative">
        <div className="grid gap-x-20 gap-y-6 xl:grid-cols-8">
          <div className="xl:col-span-3">
            <h4 className="text-center font-medium text-3xl leading-tight md:text-left md:text-5xl">
              Subscribe to our <span className="text-turquoise">newsletter</span>
            </h4>
          </div>
          <div className="xl:col-span-5 xl:ml-4">
            <SubscribeForm />
          </div>
        </div>
      </Container>
    </section>
  )
}

const formSchema = z.object({
  contact: z.string().email('Invalid email address'),
})

type FormData = z.infer<typeof formSchema>

const SubscribeForm = () => {
  const [responseMessage, setResponseMessage] = useState<string | null>(null)
  const [isError, setIsError] = useState(false)

  const form = useForm<FormData>({
    resolver: zodResolver(formSchema),
  })

  const { register, handleSubmit, reset, formState } = form
  const { errors } = formState

  const onSubmit = handleSubmit(async (data: FormData) => {
    try {
      await axios.post('https://qubelabs.io/newsletter.php', data)
      setResponseMessage('You have successfully subscribed to our newsletter.')
      setIsError(false)
      reset()
    } catch (error) {
      setResponseMessage('There was an error submitting the form.')
      console.error('Error submitting form:', error)
      setIsError(true)
    }
  })

  return (
    <form
      className="flex flex-col flex-wrap items-center gap-4 md:flex-row md:items-start"
      onSubmit={onSubmit}
      noValidate
    >
      <Label className="grid w-full flex-grow gap-2 text-black md:w-auto">
        <span>Email</span>
        <Input
          variant="black"
          id="contact-email"
          type="email"
          placeholder="your@email.com"
          className="peer"
          {...register('contact')}
        />
        {errors.contact && (
          <Badge color="warning" className="w-full justify-start text-left">
            {errors.contact.message}
          </Badge>
        )}
      </Label>
      {responseMessage && (
        <Badge color={isError ? 'warning' : 'turquoise'} className="w-full justify-start text-left">
          {responseMessage}
        </Badge>
      )}
      <Button type="submit" className="mt-6 w-fit min-w-52">
        <span>Subscribe</span>
        <ButtonArrow />
      </Button>
    </form>
  )
}
