import type { StakingProject } from '@/types/stakingProject'
import { cn } from '@/utils/cn'
import { copyToClipboard } from '@/utils/copyToClipboard'
import { formatValueToPercentage } from '@/utils/formatters'
import { Button, ButtonArrow } from '../common/button'
import { Dialog, DialogContent, DialogDescription, DialogTitle, DialogTrigger } from '../common/dialog'
import { ScrollArea } from '../common/scrollArea'
import { Separator } from '../common/separator'
import { ArrowIcon } from '../icons/arrow'
import { CopyIcon } from '../icons/copy'

export const StakeProject = ({ ...props }: StakingProject) => {
  return (
    <Dialog modal={false}>
      <StakeTrigger {...props} />
      <StakeModal {...props} />
    </Dialog>
  )
}

const StakeTrigger = (props: StakingProject) => {
  const { name, image, apr, aprOverride } = props
  const displayApr = aprOverride ? aprOverride : apr ? calculateApr(apr) : 'N/A'

  return (
    <DialogTrigger asChild>
      <Button
        variant="default"
        size="default"
        className={cn(
          'group flex xs:grid min-h-24 w-full xs:grid-cols-[6.5rem_auto_7.5rem_1fr] flex-wrap items-center xs:items-center xs:justify-start gap-4 rounded-lg border border-white/20 p-4 text-left text-white transition-all md:grid-cols-[1fr_auto_7.5rem_auto] md:gap-6 xl:p-6',
          'hover:bg-white/20 hover:text-white focus-visible:bg-white/20 focus-visible:text-white'
        )}
      >
        <div className="max-xs:-order-2 flex flex-grow flex-col gap-x-4 gap-y-1 xl:flex-row xl:items-center">
          <img
            src={image}
            alt={name}
            className={cn(
              'size-10 min-w-10 object-fit transition-all md:size-11 md:min-w-11 xl:size-16 xl:min-w-16',
              'brightness-0 grayscale invert group-hover:brightness-100 group-hover:grayscale-0 group-hover:invert-0 group-focus-visible:brightness-100 group-focus-visible:grayscale-0 group-focus-visible:invert-0'
            )}
          />
          <p className="font-bold text-base xl:text-2xl">{name}</p>
        </div>
        <Separator orientation="vertical" className="xs:block hidden h-14 bg-white/20" />
        <Separator orientation="horizontal" className="block xs:hidden w-full bg-white/20" />
        <p className="flex flex-grow flex-col-reverse gap-y-1">
          <span className="block font-bold text-white/50 text-xs">Annual Reward Rate</span>
          <span className="block h-10 font-bold text-3xl transition-all md:h-11">{displayApr}</span>
        </p>
        <ArrowIcon className="max-xs:-order-1 ml-auto w-[unset]" />
      </Button>
    </DialogTrigger>
  )
}

const StakeModal = (props: StakingProject) => {
  const {
    name,
    image,
    website,
    operator,
    apr,
    aprOverride,
    commission,
    stakeLink,
    articleLink = 'https://medium.com/@qubelabs',
  } = props

  const displayApr = aprOverride ? aprOverride : apr ? calculateApr(apr) : 'N/A'

  return (
    <DialogContent className="text-white">
      <DialogTitle className="sr-only">{name}</DialogTitle>
      {apr && (
        <DialogDescription className="sr-only">
          Staking {name} with {displayApr} annual reward rate
        </DialogDescription>
      )}

      <div className="h-full max-h-full overflow-hidden px-9 py-20">
        <ScrollArea className="h-full">
          <div className="flex flex-col gap-6">
            <header className="flex items-center gap-4">
              <img src={image} alt={name} className="h-20 w-20 min-w-20 object-fit" />
              <div className="flex max-w-full flex-col gap-2 overflow-hidden">
                <h6 className="font-bold text-3xl leading-tight">{name}</h6>
                <Button
                  variant="default"
                  size="default"
                  className="inline overflow-hidden text-ellipsis whitespace-nowrap font-bold text-base text-turquoise underline"
                  asChild
                >
                  <a href={website} target="_blank" rel="noreferrer">
                    {website}
                  </a>
                </Button>
              </div>
            </header>
            <div className="rounded-2xl bg-white/10 p-4">
              <div className="flex max-w-full items-center gap-2 overflow-hidden">
                <span className="overflow-hidden text-ellipsis whitespace-nowrap font-bold text-turquoise">
                  {operator ?? 'N/A'}
                </span>
                {operator && (
                  <Button
                    variant="default"
                    size="default"
                    className="text-turquoise"
                    onClick={() => copyToClipboard(operator)}
                  >
                    <span className="sr-only">Copy operator address</span>
                    <CopyIcon size={24} />
                  </Button>
                )}
              </div>
              <span className="font-bold text-white/50">Operator Address</span>
            </div>
            <p>
              <span className="block font-bold text-6xl">{displayApr}</span>
              <span className="block font-bold text-white/50">Annual Reward Rate</span>
            </p>
            <p>
              <span className="block font-bold text-4xl">{commission}</span>
              <span className="block font-bold text-white/50">Commission</span>
            </p>
            <div className="flex flex-col gap-2">
              <Button className="min-w-52" asChild>
                <a href={stakeLink} target="_blank" rel="noreferrer">
                  <span>Stake</span>
                  <ButtonArrow />
                </a>
              </Button>
              {articleLink && (
                <Button
                  variant="default"
                  size="default"
                  className="flex items-center gap-2 font-medium text-xt hover:text-white hover:underline focus-visible:text-white focus-visible:underline"
                  asChild
                >
                  <a href={articleLink} target="_blank" rel="noreferrer">
                    <span>Learn how to stake</span>
                    <ArrowIcon />
                  </a>
                </Button>
              )}
            </div>
          </div>
        </ScrollArea>
      </div>
    </DialogContent>
  )
}

function calculateApr(apr: number) {
  return formatValueToPercentage(apr * 100)
}
