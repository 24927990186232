export function formatValueToPercentage(
  value = 0,
  options?: Intl.NumberFormatOptions & { fractionDigits: number }
): string {
  const { fractionDigits = 2 } = options ?? {}
  const percentageFormatter = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: fractionDigits,
    maximumFractionDigits: fractionDigits,
    ...options,
  })
  return `${percentageFormatter.format(value)}%`
}
