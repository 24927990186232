import type { ReactNode } from 'react'
import { Badge } from '../common/badge'

export interface HighlightProps {
  icon: ReactNode
  title: string
  text: string
}

export const Highlight = ({ icon, title, text }: HighlightProps) => {
  return (
    <div className="flex max-w-xl flex-col gap-10 px-5 py-8">
      <Badge color="orange" variant="square">
        {icon}
      </Badge>
      <span className="font-bold text-2xl">{title}</span>
      <p className="text-black/80">{text}</p>
    </div>
  )
}
