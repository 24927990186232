import { cn } from '@/utils/cn'
import { type IconProps, iconVariants } from './common'

export function MediumIcon({ className, size = 24, ...props }: IconProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 32 32"
      color="currentColor"
      className={cn(iconVariants({ size, className }))}
      {...props}
    >
      <path
        d="M26.1149 7.64454L26.1402 7.63901V7.4436H20.9868L16.2017 18.6901L11.4166 7.4436H5.86317V7.63901L5.88811 7.64454C6.82873 7.85685 7.3063 8.17348 7.3063 9.31535V22.6835C7.3063 23.8254 6.82689 24.142 5.88627 24.3543L5.86133 24.3599V24.5559H9.62914V24.3605L9.60423 24.3549C8.66358 24.1426 8.18602 23.826 8.18602 22.6841V10.0908L14.333 24.5559H14.6816L21.0077 9.68738V23.0143C20.9271 23.9162 20.4538 24.1946 19.6021 24.3869L19.5769 24.3928V24.5867H26.1402V24.3928L26.1149 24.3869C25.2623 24.1946 24.7776 23.9162 24.697 23.0143L24.6927 9.31535H24.697C24.697 8.17348 25.1746 7.85685 26.1149 7.64454Z"
        fill="currentColor"
      />
    </svg>
  )
}
