import { Links, Meta, Outlet, Scripts, ScrollRestoration } from '@remix-run/react'

import type { LinksFunction } from '@remix-run/node'
import { Footer } from './components/footer'
import { Header } from './components/header'
import { Providers } from './providers/providers'
import styles from './tailwind.css?url'

export const links: LinksFunction = () => [
  { rel: 'preconnect', href: 'https://fonts.googleapis.com' },
  {
    rel: 'preconnect',
    href: 'https://fonts.gstatic.com',
    crossOrigin: 'anonymous',
  },
  {
    rel: 'stylesheet',
    href: 'https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300..700&display=swap',
  },
  { rel: 'stylesheet', href: styles },
]

export function Layout({ children }: { children: React.ReactNode }) {
  return (
    <html lang="en">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <Meta />
        <Links />
      </head>
      <body className="flex min-h-svh flex-grow flex-col antialiased">
        <Providers>
          <Header />
          <main className="flex flex-grow flex-col">{children}</main>
          <Footer />
        </Providers>
        <ScrollRestoration />
        <Scripts />
      </body>
    </html>
  )
}

export default function App() {
  return <Outlet />
}
