import { type IconProps, iconVariants } from '@/components/icons/common'
import { cn } from '@/utils/cn'

export function ArrowIcon({ className, size = 24, direction, ...props }: IconProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 -960 960 960"
      color="currentColor"
      className={cn(iconVariants({ size, direction, className }))}
      {...props}
    >
      <g className="origin-center [transform-box:fill-box]" data-direction>
        <path d="M504-480 320-664l56-56 240 240-240 240-56-56 184-184Z" fill="currentColor" />
      </g>
    </svg>
  )
}
