import { cn } from '@/utils/cn'
import useEmblaCarousel, { type UseEmblaCarouselType } from 'embla-carousel-react'
import {
  type ComponentProps,
  type HTMLAttributes,
  type KeyboardEvent,
  createContext,
  forwardRef,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'
import { ArrowIcon } from '../icons/arrow'
import { Button, type ButtonProps } from './button'

export type CarouselApi = UseEmblaCarouselType[1]
type UseCarouselParameters = Parameters<typeof useEmblaCarousel>
type CarouselOptions = UseCarouselParameters[0]
type CarouselPlugin = UseCarouselParameters[1]

type CarouselProps = {
  opts?: CarouselOptions
  plugins?: CarouselPlugin
  orientation?: 'horizontal' | 'vertical'
  setApi?: (api: CarouselApi) => void
}

type CarouselContextProps = {
  carouselRef: ReturnType<typeof useEmblaCarousel>[0]
  api: ReturnType<typeof useEmblaCarousel>[1]
  scrollPrev: () => void
  scrollNext: () => void
  canScrollPrev: boolean
  canScrollNext: boolean
} & CarouselProps

const CarouselContext = createContext<CarouselContextProps | null>(null)

function useCarousel() {
  const context = useContext(CarouselContext)

  if (!context) {
    throw new Error('useCarousel must be used within a <Carousel />')
  }

  return context
}

export const Carousel = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement> & CarouselProps>(
  ({ orientation = 'horizontal', opts, setApi, plugins, className, children, ...props }, ref) => {
    const [carouselRef, api] = useEmblaCarousel(
      {
        loop: false,
        align: 'center',
        axis: orientation === 'horizontal' ? 'x' : 'y',
        ...opts,
      },
      plugins
    )
    const [canScrollPrev, setCanScrollPrev] = useState(false)
    const [canScrollNext, setCanScrollNext] = useState(false)

    const onSelect = useCallback((api: CarouselApi) => {
      if (!api) {
        return
      }

      setCanScrollPrev(api.canScrollPrev())
      setCanScrollNext(api.canScrollNext())
    }, [])

    const scrollPrev = useCallback(() => {
      api?.scrollPrev()
    }, [api])

    const scrollNext = useCallback(() => {
      api?.scrollNext()
    }, [api])

    const handleKeyDown = useCallback(
      (event: KeyboardEvent<HTMLDivElement>) => {
        if (event.key === 'ArrowLeft') {
          event.preventDefault()
          scrollPrev()
        } else if (event.key === 'ArrowRight') {
          event.preventDefault()
          scrollNext()
        }
      },
      [scrollPrev, scrollNext]
    )

    useEffect(() => {
      if (!api || !setApi) {
        return
      }

      setApi(api)
    }, [api, setApi])

    useEffect(() => {
      if (!api) {
        return
      }

      onSelect(api)
      api.on('reInit', onSelect)
      api.on('select', onSelect)

      return () => {
        api?.off('select', onSelect)
      }
    }, [api, onSelect])

    return (
      <CarouselContext.Provider
        value={{
          carouselRef,
          api: api,
          opts,
          orientation: orientation || (opts?.axis === 'y' ? 'vertical' : 'horizontal'),
          scrollPrev,
          scrollNext,
          canScrollPrev,
          canScrollNext,
        }}
      >
        <div
          ref={ref}
          onKeyDownCapture={handleKeyDown}
          className={cn('relative flex items-center justify-center', className)}
          role="region"
          aria-roledescription="carousel"
          data-role="carousel"
          {...props}
        >
          {children}
        </div>
      </CarouselContext.Provider>
    )
  }
)
Carousel.displayName = 'Carousel'

export const CarouselViewport = ({ className, ...props }: HTMLAttributes<HTMLDivElement>) => {
  const { carouselRef } = useCarousel()

  return <div ref={carouselRef} className={cn('overflow-hidden', className)} data-role="carousel-viewport" {...props} />
}

export const CarouselContent = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(
  ({ className, ...props }, ref) => {
    const { orientation } = useCarousel()

    return (
      <div
        ref={ref}
        className={cn('flex', orientation === 'horizontal' ? 'flex-row' : 'flex-col', className)}
        data-role="carousel-content"
        {...props}
      />
    )
  }
)
CarouselContent.displayName = 'CarouselContent'

export const CarouselItem = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(
  ({ className, ...props }, ref) => {
    const { orientation } = useCarousel()

    return (
      <div
        ref={ref}
        role="group"
        aria-roledescription="slide"
        data-role="carousel-slide"
        className={cn('min-w-0 shrink-0 grow-0 basis-full', orientation === 'horizontal' ? 'pl-4' : 'pt-4', className)}
        {...props}
      />
    )
  }
)
CarouselItem.displayName = 'CarouselItem'

const carouselButtonStyles = cn(
  'relative size-14 min-w-14 rounded-full border border-light bg-white p-0 text-black',
  'before:absolute before:inset-0 before:rounded-full before:border-[16px] before:border-turquoise before:opacity-0 before:transition-all before:duration-500 hover:before:border-[6px] hover:before:border-turquoise-dark hover:before:opacity-100',
  'disabled:opacity-50'
)

export const CarouselPrevious = forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant = 'default', onClick, ...props }, ref) => {
    const { orientation, scrollPrev, canScrollPrev } = useCarousel()

    return (
      <Button
        ref={ref}
        variant={variant}
        className={cn(carouselButtonStyles, className)}
        disabled={!canScrollPrev}
        onClick={(e) => {
          onClick?.(e)
          scrollPrev()
        }}
        data-role="carousel-previous-slide-button"
        {...props}
      >
        <ArrowIcon direction={orientation === 'horizontal' ? 'left' : 'up'} size={16} />
        <span className="sr-only">Previous slide</span>
      </Button>
    )
  }
)
CarouselPrevious.displayName = 'CarouselPrevious'

export const CarouselNext = forwardRef<HTMLButtonElement, ComponentProps<typeof Button>>(
  ({ className, variant = 'default', onClick, ...props }, ref) => {
    const { orientation, scrollNext, canScrollNext } = useCarousel()

    return (
      <Button
        ref={ref}
        variant={variant}
        className={cn(carouselButtonStyles, className)}
        disabled={!canScrollNext}
        onClick={(e) => {
          onClick?.(e)
          scrollNext()
        }}
        data-role="carousel-next-slide-button"
        {...props}
      >
        <ArrowIcon direction={orientation === 'horizontal' ? 'right' : 'down'} size={16} />
        <span className="sr-only">Next slide</span>
      </Button>
    )
  }
)
CarouselNext.displayName = 'CarouselNext'
