import { cn } from '@/utils/cn'
import { type VariantProps, cva } from 'class-variance-authority'
import type { HTMLAttributes } from 'react'

const badgeVariants = cva(
  'inline-flex w-fit items-center justify-center text-center font-bold text-sm leading-none transition-color',
  {
    variants: {
      variant: {
        round: 'min-h-9 rounded-full px-4 py-2',
        square: 'min-h-11 min-w-11 rounded-lg p-2',
      },
      color: {
        turquoise: 'bg-turquoise/20 text-turquoise',
        orange: 'bg-orange/10 text-orange',
        warning: 'bg-warning/10 text-warning',
      },
    },
    defaultVariants: {
      variant: 'round',
      color: 'turquoise',
    },
  }
)

interface BadgeProps extends Omit<HTMLAttributes<HTMLDivElement>, 'color'>, VariantProps<typeof badgeVariants> {}

export function Badge({ className, variant, color, ...props }: BadgeProps) {
  return <div className={cn(badgeVariants({ variant, color }), className)} {...props} />
}
