export interface TestimonialProps {
  text: string
  accentText?: string
}

export const Testimonial = ({ text, accentText }: TestimonialProps) => {
  return (
    <div className="flex flex-col gap-8 md:flex-row">
      <svg width="75" height="57" viewBox="0 0 75 57" className="h-14 w-[4.5rem] min-w-[4.5rem] text-turquoise/20">
        <path
          d="M60.1264 56.3367c-6.2123 0-10.6497-1.9968-13.3121-5.9905-2.6624-4.1415-3.9936-8.505-3.9936-13.0903 0-3.8457.5916-7.8393 1.7749-11.9809 1.1833-4.2894 3.0322-8.357 5.5468-12.2028 2.5145-3.84568 5.7685-6.95184 9.7622-9.31845C64.0461 1.38716 69.0012.203857 74.7698.203857c.1479 0 .1479.591655 0 1.774953V7.52553s-.8135.29582-2.4406.88747c-1.627.44375-3.5499 1.40518-5.7686 2.8843-2.2186 1.3312-4.1415 3.2541-5.7685 5.7686-1.6271 2.5145-2.4406 5.7686-2.4406 9.7622.2958-.1479.6656-.2218 1.1093-.2218h1.1094c4.2894 0 7.6914 1.3312 10.206 3.9936 2.6624 2.6624 3.9936 6.0644 3.9936 10.206 0 4.5852-1.4052 8.357-4.2155 11.3153-2.6624 2.8103-6.1384 4.2155-10.4279 4.2155Zm-42.8206 0c-6.2124 0-10.64974-1.9968-13.31216-5.9905C1.33121 46.2047 0 41.8412 0 37.2559c0-3.8457.59165-7.8393 1.77495-11.9809 1.1833-4.2894 3.03221-8.357 5.54672-12.2028 2.51451-3.84568 5.76863-6.95184 9.76223-9.31845C21.2255 1.38716 26.1805.203857 31.9491.203857c.1479 0 .1479.591655 0 1.774953V7.52553s-.8135.29582-2.4405.88747c-1.6271.44375-3.5499 1.40518-5.7686 2.8843-2.2187 1.3312-4.1416 3.2541-5.7686 5.7686-1.6271 2.5145-2.4406 5.7686-2.4406 9.7622.2958-.1479.6656-.2218 1.1094-.2218h1.1093c4.2895 0 7.6915 1.3312 10.206 3.9936 2.6624 2.6624 3.9936 6.0644 3.9936 10.206 0 4.5852-1.4052 8.357-4.2155 11.3153-2.6624 2.8103-6.1384 4.2155-10.4278 4.2155Z"
          fill="currentColor"
        />
      </svg>
      <blockquote className="inline font-medium text-2xl leading-tight md:text-5xl">
        <p className="inline">{text}</p>
        {accentText && (
          <>
            {' '}
            <h5 className="inline text-turquoise">{accentText}</h5>
          </>
        )}
      </blockquote>
    </div>
  )
}
