import { cn } from '@/utils/cn'
import { animated, easings, useSprings } from '@react-spring/web'
import { useCallback, useState } from 'react'

export const SquareAnimation = () => {
  const [turquoiseReverse, setTurquoiseReverse] = useState(false)
  const [orangeReverse, setOrangeReverse] = useState(false)

  const handleTurquoiseRest = useCallback(() => {
    setTurquoiseReverse((prev) => !prev)
  }, [])

  const handleOrangeRest = useCallback(() => {
    setOrangeReverse((prev) => !prev)
  }, [])

  const blueIndices = [0, 1, 3, 7, 9]
  const orangeIndices = [2, 4, 5, 6, 8]

  const blueSprings = useSprings(
    blueIndices.length,
    blueIndices.map((index) => {
      const angle = getAngleForIndex(index)
      const startAngle = angle * 90 - 45
      const endAngle = startAngle + 180

      return {
        from: {
          background: `conic-gradient(from ${startAngle}deg, #000000, #108EAD 35%, #3AB7D6 70%, #FFFFFF)`,
        },
        to: {
          background: `conic-gradient(from ${endAngle}deg, #000000, #D86700 35%, #FFA04A 70%, #FFFFFF)`,
        },
        delay: 1000,
        reverse: turquoiseReverse,
        config: {
          duration: 1800,
          easing: easings.easeOutSine,
        },
        onRest: handleTurquoiseRest,
      }
    })
  )

  const orangeSprings = useSprings(
    orangeIndices.length,
    orangeIndices.map((index) => {
      const angle = getAngleForIndex(index)
      const startAngle = angle * 90 - 45
      const endAngle = startAngle + 180

      return {
        from: {
          background: `conic-gradient(from ${startAngle}deg, #000000, #D86700 35%, #FFA04A 70%, #FFFFFF)`,
        },
        to: {
          background: `conic-gradient(from ${endAngle}deg, #000000, #108EAD 35%, #3AB7D6 70%, #FFFFFF)`,
        },
        delay: 2000,
        reverse: orangeReverse,
        config: {
          duration: 1800,
          easing: easings.easeOutSine,
        },
        onRest: handleOrangeRest,
      }
    })
  )

  return (
    <div
      className={cn(
        'user-select-none pointer-events-none mx-auto w-full max-w-[1440px]',
        'xl:-translate-x-1/2 xl:absolute xl:top-0 xl:left-1/2 xl:h-full'
      )}
    >
      <div
        className={cn(
          'ml-auto grid h-full grid-rows-4',
          'grid-cols-4',
          'md:grid-rows-2',
          'xl:aspect-square xl:grid-rows-4'
        )}
      >
        {blueSprings.map((style, index) => (
          <animated.div
            key={blueIndices[index]}
            className={cn('aspect-square', getClassForIndex(blueIndices[index]))}
            style={{ ...style, order: blueIndices[index] + 1 }}
          />
        ))}
        {orangeSprings.map((style, index) => (
          <animated.div
            key={orangeIndices[index]}
            className={cn('aspect-square', getClassForIndex(orangeIndices[index]))}
            style={{ ...style, order: orangeIndices[index] + 1 }}
          />
        ))}
      </div>
    </div>
  )
}

function getAngleForIndex(index: number) {
  switch (index) {
    case 2:
    case 5:
      return 2
    case 3:
    case 6:
    case 7:
    case 9:
      return 4
    default:
      return 1
  }
}

const getClassForIndex = (index: number) => {
  switch (index) {
    case 0:
      return 'col-span-2 row-span-2'
    case 5:
    case 7:
    case 8:
    case 9:
      return 'md:hidden xl:block'
    case 6:
      return 'col-span-2 row-span-2 md:hidden xl:block'
    default:
      return ''
  }
}
