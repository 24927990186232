import agoricImage from '/images/stake/agoric.svg'
import axelarImage from '/images/stake/axelar.png'
import celestiaImage from '/images/stake/celestia.svg'
import dymensionImage from '/images/stake/dymension.svg'
import evmosImage from '/images/stake/evmos.svg'
import minaImage from '/images/stake/mina.png'
import nolusImage from '/images/stake/nolus.svg'
import nomicImage from '/images/stake/nomic.svg'
import obolImage from '/images/stake/obol.png'
import umeeImage from '/images/stake/umee.png'
import nillionImage from '/images/stake/nillion.png'
import astriaImage from '/images/stake/astria.svg'

import type { StakingProject } from '@/types/stakingProject'

export const STAKE_PROJECTS: Omit<StakingProject, 'apr'>[] = [
  {
    fetchId: 'celestia',
    name: 'Celestia',
    image: celestiaImage,
    website: 'https://celestia.org/',
    operator: 'celestiavaloper1j2jq259d3rrc24876gwxg0ksp0lhd8gy49k6st',
    commission: '5%',
    stakeLink:
      'https://wallet.keplr.app/explore?modal=staking&chain=celestia&validator_address=celestiavaloper1j2jq259d3rrc24876gwxg0ksp0lhd8gy49k6st&step_id=2',
    articleLink: 'https://docs.celestia.org/learn/how-to-stake-tia',

  },
  {
    fetchId: 'axelar',
    name: 'Axelar Network',
    image: axelarImage,
    website: 'https://axelar.network/',
    operator: 'axelarvaloper19wz0kfzj2czmjg9052h69wk6kgxc848hxs8rhl',
    commission: '10%',
    stakeLink:
      'https://wallet.keplr.app/explore?modal=staking&chain=axelar-dojo-1&validator_address=axelarvaloper19wz0kfzj2czmjg9052h69wk6kgxc848hxs8rhl&step_id=2',
    articleLink: 'https://medium.com/qubelabs/how-to-stake-axl-token-with-keplr-wallet-ceca6af433eb',
    aprOverride: '7.00%', 
  },
  {
    fetchId: 'dymension',
    name: 'Dymension',
    image: dymensionImage,
    website: 'https://dymension.xyz/',
    operator: 'dymvaloper12qeeprgtt42ujha56pp4kcnv0hpfy0kgvzwqef',
    commission: '5%',
    stakeLink:
      'https://wallet.keplr.app/?modal=staking&chain=dymension_1100-1&validator_address=dymvaloper12qeeprgtt42ujha56pp4kcnv0hpfy0kgvzwqef&step_id=2',
  },
  {
    fetchId: 'obol',
    name: 'Eth. via Obol',
    image: obolImage,
    website: 'https://obol.tech/',
    operator: '0xb2a4d7c8a5391c52da1d845cfd526d469f247d56f685b46333e203befb93bab0312b3e888bd1a7a6a36acd57af6654dd',
    commission: '5%',
    stakeLink:
      'https://mainnet.beaconcha.in/validator/0x0xb2a4d7c8a5391c52da1d845cfd526d469f247d56f685b46333e203befb93bab0312b3e888bd1a7a6a36acd57af6654dd#deposits',
    aprOverride: '8.00%',
  },
  {
    fetchId: 'umee',
    name: 'Umee',
    image: umeeImage,
    website: 'https://umee.cc/',
    operator: 'umeevaloper1qzulky8hacycvxrz2jcts28zg7nypce88590zp',
    commission: '10%',
    stakeLink:
      'https://wallet.keplr.app/chains/umee?modal=staking&chain=umee-1&validator_address=umeevaloper1qzulky8hacycvxrz2jcts28zg7nypce88590zp&step_id=2',
    articleLink: 'https://medium.com/qubelabs/how-to-stake-umee-token-with-keplr-wallet-b7dd287314ae',
   },
  {
    fetchId: 'agoric',
    name: 'Agoric',
    image: agoricImage,
    website: 'https://agoric.com/',
    operator: 'agoricvaloper1x34ru60cx0jhaeucnwccg904q26yycw7jmgxxt',
    commission: '8%',
    stakeLink:
      'https://wallet.keplr.app/chains/agoric?modal=staking&chain=agoric-3&validator_address=agoricvaloper1x34ru60cx0jhaeucnwccg904q26yycw7jmgxxt&step_id=2',
    articleLink: 'https://medium.com/qubelabs/how-to-stake-agoric-bld-with-keplr-wallet-34e9250a00f5',
  },
  {
    fetchId: 'nomic',
    name: 'Nomic',
    image: nomicImage,
    website: 'https://nomic.io/',
    operator: undefined,
    commission: '3%',
    stakeLink: 'https://app.nomic.io/staking#stake',
    aprOverride: '200%',
  },
  {
    fetchId: 'nolus',
    name: 'Nolus',
    image: nolusImage,
    website: 'https://nolus.io',
    operator: 'nolusvaloper1dmgk2jd4epnwtpzah52tp46765s6yy78lcsrqx',
    commission: '5%',
    stakeLink: 'https://explorer.nolus.io/pirin-1/staking/nolusvaloper1dmgk2jd4epnwtpzah52tp46765s6yy78lcsrqx',
    aprOverride: '3.20%',
  },
  {
    fetchId: 'mina',
    name: 'MINA',
    image: minaImage,
    website: 'https://minaprotocol.com/',
    operator: 'B62qm2eYCip2jy5FFvUuygSCpiPg79ZhWT5S2mr6bCBfGZ4kXtBYUxd',
    commission: '8%',
    stakeLink: 'https://www.aurowallet.com/',
    aprOverride: '13.64%',
  },
   {
    fetchId: 'astria',
    name: 'Astria',
    image: astriaImage,
    website: 'https://www.astria.org/',
    operator: 'astria18jehvrffkjzuwywj04dvtnntftcysf3acsegha',
    commission: '0%',
    stakeLink:
      'N/A',
    aprOverride: 'N/A',
  },
  {
    fetchId: 'nillion',
    name: 'Nillion',
    image: nillionImage,
    website: 'https://nillion.com/',
    operator: 'soon',
    commission: 'soon%',
    stakeLink: 'soon',
    aprOverride: 'soon',
  },
]
