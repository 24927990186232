import { cn } from '@/utils/cn'
import { getRandomInRange } from '@/utils/getRandomInRange'
import { animated, useSpring } from '@react-spring/web'
import { type VariantProps, cva } from 'class-variance-authority'
import { type HTMLAttributes, useState } from 'react'

const curveVariants = cva('absolute h-[240px] w-[176px] blur-4xl md:h-[320px] md:w-[210px] xl:h-[583px] xl:w-[384px]', {
  variants: {
    variant: {
      turquoise: 'text-[#009DC8]',
      orange: 'text-[#FB881F]',
    },
  },
  defaultVariants: {
    variant: 'turquoise',
  },
})

interface CurveAnimationProps {
  variant: VariantProps<typeof curveVariants>['variant']
  className: string
  params: ReturnType<typeof getAnimationTargetParams>
}

export const CurveAnimation = ({ variant, className, params }: CurveAnimationProps) => {
  const [reverse, setReverse] = useState(false)
  const animation = useSpring({
    from: {
      transform: 'matrix(1, 0, 0, 1, 0, 0) rotate(0deg)',
      filter: 'blur(80px)',
    },
    ...params,
    reverse: reverse,
    config: { tension: 120, friction: 14, duration: 15_000 },
    onRest: () => setReverse(!reverse),
  })

  return (
    <animated.svg
      style={animation}
      viewBox="0 0 384 583"
      fill="none"
      color="currentColor"
      className={cn(curveVariants({ variant }), className)}
    >
      <path
        d="M0.460068 0.0384731L121.035 -0.00127505C115.007 222.934 232.627 404.281 383.217 404.232L378.39 582.733C161.296 582.804 -8.22939 321.373 0.460068 0.0384731Z"
        fill="currentColor"
      />
    </animated.svg>
  )
}

export const CurveAnimationContainer = ({ className, children, ...props }: HTMLAttributes<HTMLDivElement>) => {
  return (
    <div
      className={cn('pointer-events-none absolute inset-0 h-full w-full select-none overflow-hidden', className)}
      {...props}
    >
      <div className="relative h-full w-full overflow-hidden">{children}</div>
    </div>
  )
}

export function getAnimationTargetParams() {
  return {
    to: {
      transform: `matrix(${getRandomInRange(0.6, 1.4)}, 0, 0, ${getRandomInRange(0.6, 1.4)}, ${getRandomInRange(-150, 150)}, ${getRandomInRange(-150, 150)}) rotate(${getRandomInRange(-180, 180)}deg)`,
      filter: `blur(${getRandomInRange(60, 120)}px)`,
    },
  }
}
